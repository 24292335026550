export interface INavigationRoute {
  name: string
  displayName: string
  meta: {
    userType: any, userRole: any, icon: string 
  },
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'Dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'space_dashboard',
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
    },
    {
      name: 'PlayerTransactions',
      displayName: 'menu.playerTransactions',
      meta: {
        icon: 'receipt_long',
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
    },
    {
      name: 'Users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin']
      },
    },
    {
      name: 'Companies',
      displayName: 'menu.companies',
      meta: {
        icon: 'apartment',
        userType: ['admin', 'company'],
        userRole: ['super admin', 'admin', 'finance']
      },
    },
    {
      name: 'Clients',
      displayName: 'menu.clients',
      meta: {
        icon: 'account_circle',
        userType: ['admin'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'Clients',
          displayName: 'menu.clients',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'ClientGroups',
          displayName: 'menu.clientGroups',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
      ]
    },
    {
      name: 'BankAccounts',
      displayName: 'menu.bankAccounts',
      meta: {
        icon: 'credit_card',
        userType: ['admin', 'company'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
    },
    {
      name: 'Finance',
      displayName: 'menu.finance',
      meta: {
        icon: 'payments',
        userType: ['admin', 'client', 'company'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'ClientTransactions',
          displayName: 'menu.clientTransactions',
          meta: {
            userType: ['admin', 'client'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'CompanyTransactions',
          displayName: 'menu.companyTransactions',
          meta: {
            userType: ['admin', 'company'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'NewManualDeposit',
          displayName: 'menu.newManualDeposit',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin']
          }
        },
        {
          name: 'NewManualWithdraw',
          displayName: 'menu.newManualWithdraw',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin']
          }
        },
        {
          name: 'CompanyBalances',
          displayName: 'menu.companyBalances',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'ClientBalances',
          displayName: 'menu.clientBalances',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
      ],
    },
    {
      name: 'Reports',
      displayName: 'menu.reports',
      meta: {
        icon: 'summarize',
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'ClientReports',
          displayName: 'menu.clientReports',
          meta: {
            userType: ['admin', 'client'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'CompanyReports',
          displayName: 'menu.companyReports',
          meta: {
            userType: ['admin', 'company'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'DepositWithdrawReports',
          displayName: 'menu.depositWithdrawReports',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin']
          }
        },
        {
          name: 'Notifications',
          displayName: 'menu.excelExport',
          meta: {
            userType: ['admin', 'client'],
            userRole: ['super admin', 'admin', 'finance']
          }
        }
      ],
    },
    {
      name: 'Settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
        userType: ['admin'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'Banks',
          displayName: 'menu.banks',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'Vendors',
          displayName: 'menu.vendors',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'CallbackLogs',
          displayName: 'menu.callbackLogs',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin']
          }
        },
      ],
    },
    {
      name: 'Logout',
      displayName: 'menu.logout',
      meta: {
        icon: 'logout',
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
    },
  ] as INavigationRoute[],
}
