{
  "auth": {
    "email": "E-Posta",
    "login": "Giriş",
    "password": "Şifre",
    "emailValid": "Lütfen geçerli eposta adresini giriniz",
    "loggedIn": "Başarıyla giriş yaptınız",
    "enable2fa": " 2FA Etkinleştir",
    "verify2fa": "2FA Doğrula",
    "disable2fa": "2FA Pasif Yap",
    "enter2faCode": "6 Haneli 2FA Kodunu Buraya Girin",
    "email_or_username": "Email / Kullanıcı Adı"
  },
  "404": {
    "title": "Bu sayfa balık tutmaya gitti.",
    "text": "Aradığınız sayfa, adı değiştirilmiş veya geçici olarak kullanılamıyor olabilir.",
    "backButton": "Ana sayfaya git"
  },
  "menu": {
    "home": "Anasayfa",
    "auth": "Giriş",
    "buttons": "Butonlar",
    "dashboard": "Panel",
    "login": "Giriş",
    "logout": "Çıkış",
    "account": "Hesap Bilgileri",
    "settings": "Ayarlar",
    "userSettings": "Kullanıcı Ayarları",
    "users": "Kullanıcılar",
    "companies": "Şirketler",
    "clients": "Müşteriler",
    "rejectionMessages": "Ret Mesajları",
    "bankAccounts": "Banka Hesapları",
    "banks": "Bankalar",
    "vendors": "Sağlayıcılar",
    "finance": "Finans",
    "reports": "Raporlar",
    "clientTransactions": "Müşteri İşlemleri",
    "companyTransactions": "Şirket İşlemleri",
    "roles": "Roller",
    "playerTransactions": "Oyuncu İşlemleri",
    "clientReports": "Müşteri Raporları",
    "companyReports": "Şirket Raporları",
    "companyLimitReport": "Şirket Limit Raporu",
    "marsDepositReport": "Mars Para Yatırma Raporu",
    "callbackLogs": "Callback Logları",
    "newManualDeposit": "Yeni Cari Girişi",
    "newManualWithdraw": "Yeni Cari Çıkışı",
    "clientGroups": "Müşteri Grupları",
    "companyBalances": "Şirket Bakiyesi",
    "clientBalances": "Müşteri Bakiyesi",
    "depositWithdrawReports": "Yatırım Çekim Raporları",
    "notifications": "Bildirimler",
    "excelExport": "Excel Raporlar"
  },
  "user": {
    "language": "Dil Değiştir",
    "logout": "Çıkış",
    "profile": "Profil",
    "settings": "Ayarlar",
    "account": "Hesap"
  },
  "theme": {
    "dark": "Koyu",
    "light": "Açık"
  },
  "language": {
    "en": "EN",
    "tr": "TR"
  },
  "filter": {
    "bank": "Banka",
    "search": "Ara",
    "sort": "Sırala",
    "status": "Durum",
    "OTPstatus": "OTP Durumu",
    "depositStatus": "Yatırım Durumu",
    "withdrawStatus": "Çekim Durumu",
    "depositLimit": "Yatırım Limiti",
    "depositAmount": "Yatırım Miktarı",
    "depositCommissionRate": "Yatırım Komisyon Oranı",
    "withdrawCommissionRate": "Çekim Komisyon Oranı",
    "clients": "Müşteriler",
    "companies": "Firmalar",
    "userType": "Kullanıcı Tipi",
    "type": "İşlem"
  },
  "form": {
    "id": "Id",
    "name": "İsim",
    "surname": "Soyisim",
    "email": "E-Posta",
    "username": "Kullanıcı adı",
    "password": "Şifre",
    "passwordConfirm": "Şifre Tekrar",
    "iban": "IBAN",
    "OTPcode": "OTP Kod",
    "status": "Durumu",
    "userStatus": "Kullanıcı Durumu",
    "OTPstatus": "OTP Durumu",
    "userType": "Kullanıcı Tipi",
    "company": "Şirket",
    "role": "Rol",
    "client": "Müşteri",
    "clientGroup": "Müşteri Grup",
    "redirectUrl": "Yönlendirme Url",
    "redirectType": "Yönlendirme Tipi",
    "depositStatus": "Yatırım Durumu",
    "withdrawStatus": "Çekim Durumu",
    "depositType": "Yatırım Tipi",
    "withdrawType": "Çekim Tipi",
    "vendor": "Sağlayıcı Firma",
    "bank": "Banka",
    "bankCode": "Banka Kodu",
    "fastLimitAmount": "Hızlı Limit Tutarı",
    "minAmount": "Min Miktar",
    "maxAmount": "Max Miktar",
    "type": "İşlem",
    "description": "Açıklama",
    "depositLimit": "Yatırım Limiti",
    "depositAmount": "Yatırım Miktarı",
    "depositCommissionRate": "Yatırım Komisyon Oranı",
    "withdrawCommissionRate": "Çekim Komisyon Oranı",
    "merchantToken": "Merchant Token",
    "webhookUrl": "Webhook Url",
    "withdrawProtectLimit": "Yatırım Koruma Limiti",
    "fastLimit": "Fast İşlem Limiti",
    "fastMinDepositAmount": "Fast Min Yatırım Miktarı",
    "advanceLimitAmount": "Avans Limit Miktarı",
    "startDate": "Başlangıç Tarihi",
    "bet_provider_txid": "Bahis Sağlayıcı TX Id",
    "player_username": "Oyuncu Kullanıcı adı",
    "sender_fullname": "Gönderici İsim",
    "sender_iban": "Gönderici Iban",
    "receiver_fullname": "Alıcı İsim",
    "receiver_iban": "Alıcı Iban",
    "amount": "Miktar",
    "created_at": "Oluşturma Tarihi",
    "updated_at": "Güncelleme Tarihi",
    "report_date": "Rapor Tarihi",
    "bank_code": "Banka Kodu",
    "google2faEnable": "2FA Aktif",
    "google2faDisable": "2FA Pasif",
    "declineReason": "Red Sebebi",
    "user": "Kullanıcı",
    "logo": "Logo",
    "commissionRate": "Komisyon Oranı",
    "limit": "Limit",
    "http": "http://",
    "chooseLogo": "Logo Seç",
    "logoPreview": "Logo Preview",
    "minDepositAmount": "Minimum Yatırım Tutarı",
    "maxDepositAmount": "Maksimum Yatırım Tutarı",
    "playerUsername": "Oyuncu Takma isim",
    "playerFullname": "Oyuncu İsim",
    "senderFullName": "Gönderici İsim",
    "playerId": "Oyuncu Id",
    "sumDeposit" : "Toplam Yatırım",
    "countDeposit" : "Toplam Yatırım Adet",
    "sumDepositCom" : "Toplam Yatırım Komisyonu",
    "sumDepositNet" : "Toplam Yatırım Net",
    "sumWithdraw" : "Toplam Çekim",
    "countWithdraw" : "Toplam Çekim Adet",
    "sumWithdrawCom" : "Toplam Çekim Komisyonu",
    "sumWithdrawNet" : "Toplam Çekim Net",
    "sumManualDeposit" : "Toplam Manuel Yatırım",
    "countManualDeposit" : "Toplam Manuel Yatırım Adet",
    "sumManualDepositCom" : "Toplam Manuel Yatırım Komisyonu",
    "sumManualDepositNet" : "Toplam Manuel Yatırım Net",
    "sumManualWithdraw" : "Toplam Manuel Çekim",
    "countManualWithdraw" : "Toplam Manuel Çekim Adet",
    "sumManualWithdrawCom" : "Toplam Manuel Çekim Komisyonu",
    "sumManualWithdrawNet" : "Toplam Manuel Çekim Net",
    "reportDate" : "Rapor Tarihi",
    "changedBy" : "Durumu değiştirildi",
    "companyId" : "Şirket Id",
    "clientId" : "Müşteri Id",
    "reason" : "Sebep"
  },
  "select": {
    "status": "Durum Seçin",
    "depositStatus": "Yatırım Durumu Seçin",
    "withdrawtStatus": "Çekim Durumu Seçin",
    "OTPstatus": "OTP Durumu Seçin",
    "OTPcode": "OTP Kodu Seçin",
    "company": "Şirket Seçin",
    "client": "Müşteri Seçin",
    "role": "Rol Seçin",
    "depositType": "Yatırım Tipi Seçin",
    "withdrawType": "Çekim Tipi Seçin",
    "withdrawStatus": "Çekim Durumu Seçin",
    "vendor": "Sağlayıcı Seçin",
    "bank": "Banka Seçin",
    "type": "İşlem Seçin",
    "userType": "Kullanıcı Tipi Seçin",
    "redirectType": "Yönlendirme Tipi Seçin",
    "startDate": "Başlangıç Tarihi",
    "endDate": "Bitiş Tarihi",
    "show": "Göster",
    "clientGroup": "Müşteri Grup Seçin",
    "dateField": "Tarih Alanı Seçin",
    "changeStatus": "Durum Değiştir",
    "withClientGroups": "Müşteri Grupları",
    "transactionType": "İşlem Türü"
  },
  "settings": {
    "language": "Dil Seçimi",
    "generalPreferences": "Genel Tercihler",
    "theme": "Tema",
    "notifications": "Bildirimler"
  },
  "account": {
    "name": "Ad",
    "email": "E-posta",
    "username": "Kullanıcı adı",
    "role": "Kullanıcı Rolü",
    "type": "Kullanıcı Türü",
    "memberSince": "Üyelik Tarihi",
    "company": "Şirket",
    "client": "Müşteri",
    "password": "Şifre",
    "status": "Durum",
    "statusText": "Sadece süperadmin bunu aktif veya pasif hale getirebilir."
  },
  "table": {
    "name": "Ad",
    "surname": "Soyad",
    "email": "E-Posta",
    "status": "Durum",
    "OTPstatus": "OTP Durumu",
    "role": "Rol",
    "company": "Şirket",
    "client": "Müşteri",
    "clients": "Müşteriler",
    "bank": "Banka",
    "createdAt": "Oluşturma Tarihi",
    "updatedAt": "Güncelleme Tarihi",
    "depositStatus": "Yatırım Durumu",
    "withdrawStatus": "Çekim Durumu",
    "deposit": "Teminat",
    "depositAmount": "Yatırım Miktarı",
    "depositLimit": "Yatırım Limiti",
    "limitStatus": "Limit Durumu",
    "bankCode": "Banka Kodu",
    "iban": "IBAN",
    "type": "İşlem",
    "action": "Action",
    "description": "Açıklama",
    "amount": "Miktar",
    "playerUsername": "Kullanıcı Adı",
    "playerFullname": "Oyuncu Ad Soyad",
    "senderFullName": "Gönderici Ad Soyad",
    "playerId": "Oyuncu Id",
    "receiver": "Alıcı",
    "sender_iban": "Gönderici Iban",
    "receiver_iban": "Alıcı Iban",
    "balance": "Bakiye",
    "risk": "Risk",
    "bank_name": "Banka Adı",
    "code": "Kod",
    "direction": "Yön",
    "sumDeposit" : "Yatırım ",
    "sumDepositCom" : "Yatırım Komisyonu",
    "sumDepositNet" : "Yatırım Net",
    "sumWithdraw" : "Çekim",
    "sumWithdrawCom" : "Çekim Komisyonu",
    "sumWithdrawNet" : "Çekim Net",
    "sumManualDeposit" : "Manuel Yatırım",
    "sumManualDepositCom" : "Manuel Yatırım Komisyonu",
    "sumManualDepositNet" : "Manuel Yatırım Net",
    "sumManualWithdraw" : "Manuel Çekim",
    "sumManualWithdrawCom" : "Manuel Çekim Komisyonu",
    "sumManualWithdrawNet" : "Manuel Çekim Net",
    "reportDate" : "Rapor Tarihi",
    "requestData" : "Yanıt Verisi",
    "webhookUrl" : "Webhook Url",
    "responseHttpStatus" : "Yanıt Http Durum",
    "response" : "Yanıt",
    "responseDuration" : "Yanıt Süresi",
    "playerTransactionId" : "Oyuncu İşlem ID",
    "minDepositAmount": "Min Yatırım Tutarı",
    "maxDepositAmount": "Max Yatırım Tutarı",
    "select": "Seçin",
    "title": "Başlık",
    "bet_provider_txid": "Bahis Sağlayıcı TX Id",
    "history": "Geçmiş"
  },
  "weekday": {
    "su": "PAZ",
    "mo": "PZT",
    "tu": "SAL",
    "we": "ÇAR",
    "th": "PER",
    "fr": "CUM",
    "sa": "CTS"
  },
  "month": {
    "jan": "Oca",
    "feb": "Şub",
    "mar": "Mar",
    "apr": "Nis",
    "may": "May",
    "jun": "Haz",
    "jul": "Tem",
    "aug": "Ağu",
    "sep": "Eyl",
    "oct": "Eki",
    "nov": "Kas",
    "dec": "Ara"
  },
  "common": {
    "save": "Kaydet",
    "approve": "Onayla",
    "approved": "Onaylandı",
    "decline": "Reddet",
    "declined": "Reddedildi",
    "waitingApprove": "Onay Bekliyor",
    "cancel": "İptal",
    "remove": "Sil",
    "confirmDeleteTitle": "Silmeyi Onayla",
    "confirmDeleteText": "Silmeyi onaylıyor musunuz?",
    "createUser": "Kullanıcı Oluştur",
    "editUser": "Kullanıcı Düzenle",
    "createCompany": "Şirket Oluştur",
    "editCompany": "Şirket Düzenle",
    "createClient": "Müşteri Oluştur",
    "editClient": "Müşteri Düzenle",
    "editClientGroup": "Müşteri Grup Düzenle",
    "createBankAccount": "Banka Hesabı Oluştur",
    "editBankAccount": "Banka Hesabı Düzenle",
    "createClientTransaction": "Müşteri İşlem Oluştur",
    "editClientTransaction": "Müşteri İşlem Detay",
    "createRole": "Rol Oluştur",
    "editRole": "Rol Düzenle",
    "createPlayerTransaction": "Oyuncu İşlem Oluştur",
    "approvePlayerTransaction": "Oyuncu İşlem Onayla",
    "checkPasswordsSame": "Şifreler aynı değil",
    "checkPasswordRules": "Şifre en az 8 karakter, bir büyük harf, bir küçük harf ve bir rakam içermelidir.",
    "enabled2faText": "İki Faktörlü Kimlik Doğrulama (2FA) artık hesabınız için etkinleştirildi ve oturum açma işlemlerinize ekstra bir güvenlik katmanı eklendi.",
    "disabled2faText": "Hesabınıza ekstra bir güvenlik katmanı ekleyin. Oturum açmak için kullanıcı adınız ve şifrenizle birlikte bir kod girmeniz gerekecektir.",
    "setupKey: ": "Kurulum Anahtarı",
    "copy": "Kopyala",
    "text2faAuthentication": "İki faktörlü kimlik doğrulama",
    "detail": "Detay",
    "filters": "Filtrele",
    "sort": "Sırala",
    "sortName": "Sıralama",
    "sortDir": "Sıralama Yönü",
    "deposit": "Yatırım",
    "withdraw": "Çekim",
    "actionAssign": "İşlem atama",
    "createBank": "Banka Oluştur",
    "editBank": "Banka Düzenle",
    "createVendor": "Sağlayıcı Oluştur",
    "editVendor": "Sağlayıcı Düzenle",
    "inputs": "Girişler",
    "outputs": "Çıkışlar",
    "count": "Kayıtlar",
    "playerDeposits": "Oyuncu Yatırımları",
    "currentDeposits": "Cari Yatırımlar",
    "totalDepositQuantity": "Toplam Yatırım Adeti",
    "totalDepositAmount": "Toplam Yatırım Tutarı",
    "totalDepositCommission": "Toplam Yatırım Komisyonu",
    "playerWithdraws": "Oyuncu Çekimleri",
    "currentWithdraws": "Cari Çekimler",
    "totalWithdrawQuantity": "Toplam Çekim Adeti",
    "totalWithdrawAmount": "Toplam Çekim Tutarı",
    "totalWithdrawCommission": "Toplam Çekim Komisyonu",
    "net": "Net",
    "tr": "TR",
    "totalRecord": "Toplam Kayıt",
    "total": "Toplam",
    "adminPanel": "Admin Panel",
    "refreshPage": "Yeni güncellemeler var. Yenilemek için tıklayın.",
    "newManualDepositInfoText": "Giriş işlemi gerçekleştiriyorsunuz. Yazdığınız tutar kadar giriş işlemi yapılacaktır.",
    "newManualWithdrawInfoText": "Parayı çekersiniz. Girdiğiniz miktar sisteme kaydedilir.",
    "allow": "İzin ver",
    "IOScheckHead" : "IOS Safari için yapılması gerekenler:",
    "IOScheckText" : "Önce sayfayı Ana sayfaya ekle yapınız, sonra Bildirimlere izin ver'e tıklayınız.",
    "clickForVisualHelp": "Görsel yardım için tıklayın",
    "close": "Kapat",
    "revertDeclined": "Durumu onay bekleniyor olarak güncelle",
    "confirm": "Onayla",
    "confirmApproveAmount": "Bu miktar ile onaylanacak",
    "createClientGroup": "Müşteri Grup Oluştur",
    "totalBalance": "Toplam Bakiye",
    "yesterdayClosingBalance": "Dünkü Kapanış Bakiyesi",
    "successMsg": "Başarıyla Kaydedildi",
    "selectedIds": "Seçilenler",
    "selectAll": "Tümünü Seç",
    "depositThisMonth": "Aylık Yatırım",
    "withdrawThisMonth": "Aylık Çekim",
    "dailyTotalDeposit": "Günlük Toplam Yatırım",
    "dailyTotalCountDeposit": "Günlük Toplam Yatırım Sayısı",
    "dailyTotalWithdraw": "Günlük Toplam Çekim",
    "dailyTotalCountWithdraw": "Günlük Toplam Çekim Sayısı",
    "clientDailyReport": "Müşteri Günlük Rapor",
    "companyDailyReport": "Şirket Günlük Rapor",
    "depositCommission": "Yatırım Komisyonu",
    "withdrawCommission": "Çekim Komisyon ",
    "manualDeposit" : "Cari Girişi",
    "manualWithdraw" : "Cari Çıkışı",
    "unauthorizedPage" : "Yetkisiz Sayfa",
    "totalDeposit": "Toplam Yatırım",
    "totalWithdraw": "Toplam Çekim",
    "totalManualDeposit" : "Toplam Cari Girişi",
    "totalManualWithdraw" : "Toplam Cari Çıkışı"
  }
}
