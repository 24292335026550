<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import { useGlobalStore } from '../../stores/global'
import { useRoute } from "vue-router"
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../stores/auth'

const router = useRouter()
const { t } = useI18n()
const route = useRoute()
const storeGlobal = useGlobalStore()
const showMobileMenu = ref(false)
const storeAuth = useAuthStore()

function routeHasActive(section: string) {
  return route.path.endsWith(`${section}`)
}

watch(route, (to) => {
  showMobileMenu.value = false
}, { flush: 'pre', immediate: true, deep: true })

async function logout() {
  router.push('/logout')
}

const { locale } = useI18n()

function setLanguage(item: string) {
  localStorage.setItem('locale', item)
  locale.value = item
}
const languages = [
  {
    name: t('language.en'),
    value: 'en',
  },
  {
    name: t('language.tr'),
    value: 'tr',
  },
]

function checkType(type: any) {
  if(type) {    
    const found = type.some((i:any) => i == userType.value)
    return found
  }
}

function checkRole(role: any) {
  if(role) {    
    const found = role.some((i:any) => i == userRole.value)
    return found
  }
}

const userType = computed(() => {
  const user = storeAuth.getUser
  if(user){
    if(user.user_type_info.value == 1) {
      return 'admin'
    } else if (user.user_type_info.value == 2) {
      return 'client'
    } else if (user.user_type_info.value == 3) {
      return 'company'
    } else {
      return
    }
  }
})

const userRole = computed(() => {
  const user = storeAuth.getUser
  if(user){
    return user.role.name.toLowerCase()
  }
})

const user = computed(() => {
  const user = storeAuth.getUser
  if(user){
    return user
  }
})

</script>

<template>
  <a class="mobileMenuBtn material-symbols-outlined" @click="showMobileMenu = true">menu</a>
  <div class="mobileMenu" :class="{ 'showMobileMenu': showMobileMenu }">
    <div class="mobileMenuContent" :class="{ 'showMobileMenuContent': showMobileMenu }">
      <div class="mobileMenuHeader">
        <span>SAFEPAYS</span>
        <a @click="showMobileMenu = false" class="material-symbols-outlined">cancel</a>
      </div>
      <ul class="mobileMenuList">
        <div class="mobileLanguageSelect">
          <span>{{ t('settings.language') }} : </span>
          <select 
            v-model="storeGlobal.languageCode" 
            @change="setLanguage(storeGlobal.languageCode)"
            class="capitalize border border-gray-300 text-color text-sm rounded-md outline-none focus:outline-none block w-max mr-2 p-1 md:p-2.5 h-10"
          >
            <option 
              v-for="(item, index) in languages" 
              :key="index" 
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
        </div>

        <li v-for="route in storeGlobal.mobileMenuItems" :key="route.name" class="flex flex-wrap">
          <router-link
            v-if="checkType(route.meta.userType) && checkRole(route.meta.userRole) && !route.children"
            :class="routeHasActive(route.value)" 
            :to="{ name : route.name }"
          >
            <span class="material-symbols-outlined">arrow_right</span> {{ t(route.displayName) }}
          </router-link>
          <div
            class="w-full" 
            v-if="checkType(route.meta.userType) && checkRole(route.meta.userRole) && route.children"
          >
            <a><span class="material-symbols-outlined">arrow_right</span> {{ t(route.displayName) }}</a>
            <template v-for="(childRoute, index2) in route.children" :key="index2">
              <router-link
                v-if="checkType(childRoute.meta.userType) && checkRole(childRoute.meta.userRole)"
                class="!py-[7px]"
                :to="{ name : childRoute.name }"
                >
                <span class="font-medium pl-6">{{ t(childRoute.displayName) }}</span>
              </router-link>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mobileMenuBtn {
  padding: 10px;
  font-size: 32px;
  display: none;
  font-size: 40px;
  cursor: pointer;
}
.mobileMenu {
  position: fixed;
  top: 0;
  left:0;
  bottom: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  transition: all .5s ease;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  .mobileMenuContent {
    background-color: #fff;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    color: #222;
    flex-direction: column;
    margin-right: -1000px;
    transition: all .5s ease;
    .mobileMenuHeader {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 30px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #777;
      span {
        font-size: 20px;
        font-weight: 600;
        padding-left: 10px;
      }
      a {
        font-size: 40px;
        cursor: pointer;
      }
    }
    .mobileMenuList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
      padding: 0 30px;
      overflow-y: auto;
      height: 90vh;
      padding-bottom: 40px;
      .mobileLanguageSelect {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #ddd;
      }
      li{
        display: flex;
        width: 100%;
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 10px;
          color: #222;
          font-size: 14px;
          border-bottom:1px solid #ddd;
          font-weight: 600;
          cursor: pointer;
          &.router-link-active {
            color: #3472F0;
          }
        }
      }
    }
    &.showMobileMenuContent {
      margin-right: 0;
    }
  }
  &.showMobileMenu {
    opacity: 1;
    pointer-events: auto;
    display: none;
  }
}

@media (max-width:1023px) {
  .mobileMenuBtn {
    display: flex;
  }
  .mobileMenu.showMobileMenu {
    display: flex;
  }
}

@media (max-width:480px) {
  .mobileMenu .mobileMenuContent {
    width:100%;
  }
}
</style>
