<template>
  <div class="main">
    <div
      v-if="refreshBanner"
      class="w-full bg-red-600 color-white flex items-center justify-center p-3 font-bold"
    >
      <button
        @click="refreshPage()"
        class="flex items-center justify-center"
      >
        <span class="material-symbols-outlined mr-1">info</span>
        {{ t('common.refreshPage') }}
      </button>
    </div>
    <div class="mainHeader bg-backgroundSecondary border-b border-backgroundPrimary" v-if="userAuth">
      <Header @emitMenuSize=sideMenuSizeChange />
    </div>
    <div class="mainSidebar bg-backgroundSecondary" :class="{ sideMenuSmall: sideMenuSmall }" v-if="userAuth">
      <AppSidebar :sideMenuSmall=sideMenuSmall />
    </div>
    <div class="mainContent" :class="{ 'loginContent': !userAuth, sideMenuSmall: sideMenuSmall }">
      <RouterView />
    </div>
  </div>
</template>

<script setup>
navigator.serviceWorker.register("/service-worker.js")

import Header from './components/Header.vue'
import AppSidebar from './components/sidebar/AppSidebar.vue';
import { ref, computed, reactive } from 'vue'
import { useAuthStore } from './stores/auth'
import { useColors } from 'vuestic-ui'
import { useRoute } from "vue-router"
import { useI18n } from 'vue-i18n'
import axios from 'axios';
const { t } = useI18n()

const route = useRoute()
const storeAuth = useAuthStore()
const { applyPreset, currentPresetName } = useColors()

const sideMenuSmall = ref(false)
const refreshBanner = ref(false)

const theme = computed({
  get() {
    return currentPresetName.value
  },
  set(value) {
    applyPreset(value)
  },
})

function sideMenuSizeChange() {
  sideMenuSmall.value = !sideMenuSmall.value
}

const themeStorage = localStorage.getItem('theme')
themeStorage ? theme.value = themeStorage : theme.value = 'dark'

const user = reactive({
  isAuthenticated: 0
})

const userAuth = computed(() => {
  storeAuth.isAuthenticated()
  user.isAuthenticated = storeAuth.getAuthenticatedUser
  return user.isAuthenticated != null && route.name !== 'Verify2FA'
})

async function refreshPage() {
  await axios.get(`/user`)
  .then(response => {
    const uiVersion = response.headers['x-ui-version']
    localStorage.setItem('x-ui-version', uiVersion)
    localStorage.setItem('version-update-needed', 'false')
    location.reload(true)
  })
  .catch(error => {
    console.log(error)
  })
}

const versionInterval = setInterval(
  function() {
  if(localStorage.getItem('version-update-needed') == 'true') {
    refreshBanner.value = true
  } else if(localStorage.getItem('version-update-needed') == 'false') {
    refreshBanner.value = false
  }
}, 10000)


</script>

<style lang="scss">
@import '@/scss/main.scss';
</style>
